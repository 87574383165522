import React, {Component} from 'react';
import Header from './HeaderComponent';
import Home from './HomeComponent';
import About from './AboutComponent';
import Contact from './ContactComponent';
import {Switch, Route, Redirect } from 'react-router-dom';
import Footer from './FooterComponent';

class Main extends Component {
    render () {
        return (
            <div>
                <Header />
                <Switch>
                    <Route path='/' component={Home} />
                    <Route exact path='/contactme' component={() => <Contact /> } />
                    <Route exact path='/about' render={() => <About /> } />
                    <Redirect to='/' />
                </Switch>
                <Footer />
            </div>
        );
    }
}

export default Main;