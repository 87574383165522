import React, {Component} from 'react';

class Footer extends Component {
    render () {
        return(
            <footer className = "site-footer border-top border-light">
                <div className = "container container-fluid d-flex justify-content-end">
                    <div className = "row">
                        Built using HTML, CSS, React
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;