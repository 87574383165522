import React, {Component} from 'react';
import {Button} from 'reactstrap';

class WhyHire extends Component {
    render() {
        return (
            <div className = "container container-no-outline">
                <div className = "row justify-content-center">
                    <h1>Why Hire Me</h1>
                </div>
                <div className = "row justify-content-center">
                    <p>
                        I am proficient in HTML, CSS, JavaScript, C#, React, and SASS and have worked lightly with React Native, Mongo DB, 
                        Express, Node JS, and C++. A major reason to hire me lies in the fact that I am self-taught. This means that I am always 
                        willing to do research and ask questions when something is not working the way I would like it to. It also shows that I 
                        am highly motivated to get things done because I have never had deadlines set by anyone other than myself. 
                        <br /><br />
                        On top of my previously mentioned skills, I have used technologies such as Git, emulators, Shopify, Microsoft Azure, package
                        managers, Google Analytics, AWS, and APIs, which I believe show that I am capable of learning and utilizing various technologies 
                        to create better products.
                        <br /><br />
                        My personality lends itself well to this line of work because I am curious and genuinely enjoy learning. Not only this, I gladly accept 
                        tips, ideas, and criticism. I want to put out the best work I can, and that can only happen with the help of others more knowledgable 
                        than myself. I am also patient with myself if things are not going exactly as I had hoped. In conclusion, I believe I would be a good
                        addition to just about any team.
                        <br /><br />
                        <Button size="lg" style={{ backgroundColor: '#465bd1'}}>
                            <a href= "/assets/KatelynGreenResume.pdf" style={{ color: 'White'}}>Katelyn Green Resume</a>
                        </Button>
                    </p>
                </div>
            </div>
        )
    }
}

export default WhyHire;