import React, {Component} from 'react';

class Contact extends Component {
    render () {
        return (
            <div className = "container container-outline">
                <div className = "row justify-content-center">
                    <h1>Contact</h1>
                </div>
                <div className = "row justify-content-center">
                    <p>
                        <i className = "fa fa-envelope-o fa-lg pr-2" style={{ color: '#0d793a'}}/> katelyndawngreen@gmail.com <br />
                        
                        <a 
                            role="button" 
                            aria-label="linkedIn"
                            href = "https://www.linkedin.com/in/katelyn-green-52244a207" 
                        >
                            <i className="fa fa-linkedin-square fa-2x pr-2 pt-2" style={{ color: '#0d793a'}}/>
                        </a>

                        <a 
                            role="button"
                            aria-label="facebook"
                            href = "https://facebook.com/katelyn.green.1293" 
                            style={{ color: 'White'}}
                        >
                            <i className = "fa fa-facebook-official fa-2x pl-2 pt-2" style={{ color: '#0d793a'}}/>
                        </a><br />
                    </p>
                </div>
            </div>
        );
    }
}

export default Contact; 