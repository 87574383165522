import React, {Component} from 'react';
import {Collapse, Navbar, NavbarToggler, Nav} from 'reactstrap';
import { Link} from "react-scroll";

class Header extends Component {

    constructor(props){
        super(props);

        this.state = {
            isNavOpen: false,
        };

        this.toggleNav = this.toggleNav.bind(this);
    }

    toggleNav() {
        this.setState({
            isNavOpen: !this.state.isNavOpen
        });
    }

    render () {
        return (
            <Navbar dark sticky="top" expand="md" className = "navbar-blue border-bottom border-light">
                <Link 
                    activeClass="active"
                    to="about-section" 
                    spy={true} 
                    smooth={true} 
                    duration={500} 
                    style={{ color: 'White'}} 
                >
                    Katelyn Green
                </Link>
                <NavbarToggler onClick={this.toggleNav}/>
                <Collapse isOpen={this.state.isNavOpen} navbar>
                    <Nav navbar className="m-4 ml-auto">
                        <Link 
                            activeClass="active"
                            to="about-section" 
                            spy={true} 
                            smooth={true} 
                            duration={500} 
                            style={{ color: 'White'}} 
                            className = "mr-3"
                        >
                            About
                        </Link>
                        <Link 
                            activeClass="active"
                            to="why-section" 
                            spy={true} 
                            smooth={true} 
                            duration={500} 
                            style={{ color: 'White'}} 
                            className = "mr-3"
                        >
                            Hiring
                        </Link>
                        <Link 
                            activeClass="active"
                            to="contact-section" 
                            spy={true} 
                            smooth={true} 
                            duration={500} 
                            style={{ color: 'White'}} 
                        >
                            Contact
                        </Link>
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }
}

export default Header;