import React, {Component} from 'react';

class About extends Component {
    render () {
        return(
            <div className = "container container-outline">
                <div className = "row justify-content-center">
                    <h1>About Me</h1>
                </div>
                <div className = "row justify-content-center">
                    <p>
                        I am a self-taught junior software developer living in East Texas. After receiving an associates degree from Tyler Junior College 
                        (2017 - 2019), I worked through a bootcamp run by Nucamp (2020 - 2021) where I studied Bootstrap, React, React Native, and MongoDB. 
                        I hope to continue working with React moving forward, but I enjoy learning other technologies and languages.
                        <br /><br />
                        During my time off, you can usually find me at home reading, running, or doing yoga. My personal interests range from astrology to 
                        cooking to Super Smash Bros. I'm an adventurous person, so I'm constantly looking to learn and experience new things. 
                    </p>
                </div>
            </div>
        );
    }
}

export default About;