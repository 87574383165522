import React, {Component} from 'react';
import { Element } from 'react-scroll'
import Contact from './ContactComponent';
import About from './AboutComponent';
import WhyHire from './WhyHireComponent';

class Home extends Component {
    render () {
        return (
            <div className = "container-fluid">
                <Element id='about-section' name='about-section'>
                <div className="row about-row justify-content-center">
                    <About />
                </div>
                </Element>

                <Element id='why-section' name='why-section'>
                    <div className="row why-row justify-content-center">
                        <WhyHire/>
                    </div> 
                </Element>

                <Element id='contact-section' name='contact-section'>
                <div className = "row contact-row justify-content-center">
                    <Contact />             
                </div>
                </Element>
            </div>
        );
    }
}

export default Home;